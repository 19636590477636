import React, { useState } from 'react';
import styled from 'styled-components';

import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import ShieldCheckIcon from 'mdi-react/ShieldCheckIcon';

const Styled = {
  Base: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  `,
  Container: styled.div`
    max-width: 400px;
  `,
  Status: styled.div<{ checked: boolean }>`
    text-align: center;

    h1 {
      color: ${(props) => (props.checked ? '#4caf50' : '#f44336')};

      small {
        display: block;
        font-size: 0.6em;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  `,
  Icon: styled.div<{ checked: boolean }>`
    position: relative;
    display: inline-block;
    width: 8em;
    height: 8em;
    z-index: 1;

    svg {
      width: 8em;
      height: 8em;
      color: ${(props) => (props.checked ? '#4caf50' : '#f44336')};
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      margin: -1em 0 0 -1em;
      top: 0;
      left: 0;
      width: 10em;
      height: 10em;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2em solid;
      border-color: ${(props) => (props.checked ? '#4caf50' : '#f44336')};
      transform-style: flat;
      transform: scale(1);
      z-index: -1;
      opacity: 1;

      transition: transform 1s, border-color 500ms;
      animation: ${(props) => (props.checked ? 'blip-safe' : 'blip-unsafe')}
        ${(props) => (props.checked ? 4 : 2)}s infinite ease-out;

      @keyframes blip-unsafe {
        0% {
          transform: scale(0.5);
          border-width: 0em;
          opacity: 0;
        }
        30% {
          border-width: 1em;
          opacity: 1;
        }
        100% {
          transform: scale(1);
          border-width: 0.2em;
          opacity: 0;
        }
      }

      @keyframes blip-safe {
        0% {
          transform: scale(0.5);
          border-width: 0.2em;
          opacity: 0;
        }
        30% {
          border-width: 0.2em;
          opacity: 0.5;
        }
        100% {
          transform: scale(2);
          border-width: 0.2em;
          opacity: 0;
        }
      }
    }
  `,
  List: styled.div`
    margin: 3em 0;
    padding: 0 1em;
    color: rgba(0, 0, 0, 0.54);
    box-sizing: border-box;
  `,
  Control: styled.div`
    text-align: center;

    h4 {
      color: rgba(0, 0, 0, 0.54);
    }

    p {
      font-size: 0.6em;
      color: rgba(0, 0, 0, 0.26);
    }
  `,
  Toggle: styled.div<{ checked: boolean }>`
    position: relative;
    margin: 0 auto;
    width: 120px;
    height: 64px;
    border-radius: 64px;
    background-color: ${(props) =>
      !props.checked ? 'rgba(0, 0, 0, 0.26)' : '#4caf50'};
    cursor: pointer;

    transition: background-color 200ms ease-out;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: ${(props) => (!props.checked ? 3 : 120 - 58 - 3)}px;
      height: 58px;
      width: 58px;
      background-color: #fff;
      border-radius: 50%;

      transition: left 200ms ease-out;
    }
  `,
};

export const App = () => {
  const [isChecked, setIsChecked] = useState<boolean>(
    localStorage.getItem('isChecked') === 'true'
  );

  const handleToggleClick = () => {
    localStorage.setItem('isChecked', `${!isChecked}`);
    setIsChecked(!isChecked);
  };

  return (
    <Styled.Base>
      <Styled.Container>
        <Styled.Status checked={isChecked}>
          <Styled.Icon checked={isChecked}>
            {isChecked ? <ShieldCheckIcon /> : <AlertCircleIcon />}
          </Styled.Icon>

          <h1>
            <small>You are</small>
            {isChecked ? '' : 'NOT'} PROTECTED
          </h1>
        </Styled.Status>

        <Styled.List>
          <p>
            This application uses your WiFi antenna to generate anti-5G waves
            that block the actual harmful 5G waves. The proven harmful effects
            of 5G include:
          </p>
          <ul>
            <li>Homosexuality</li>
            <li>Cancer</li>
            <li>COVID-19 (2019-nCoV)</li>
          </ul>
        </Styled.List>

        <Styled.Control>
          <Styled.Toggle checked={isChecked} onClick={handleToggleClick} />

          <h4>TURN PROTECTION {isChecked ? 'OFF' : 'ON'}</h4>

          <p>
            Free 15 minute trial. Operating cost is 0,99€ / 15 minutes after
            trial.
          </p>
        </Styled.Control>
      </Styled.Container>
    </Styled.Base>
  );
};
